import React, {useState} from 'react';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';
import logo from "../Assets/logo.jpeg";

function Navbar() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [nav, setNav] = useState(false);

    const handleNav = () =>{
        setNav(!nav);
    }
  return (
    <div className='text-white flex justify-between items-center h-24 mx-auto max-w-[1240px] '>
    <img src={logo} alt='/'  className='w-20 rounded-full mx-2'/>
        <h1 className='w-full text-3xl font-bold text-[#00df9a]'>Optimal Audit Advisors</h1>
        <ul className='hidden md:flex'>

            <a href='/'><li className='p-4'>Home</li></a>
            <a href='/About'><li className='p-4'>About</li></a>
            <a href='/Services'><li className='p-4'>Services</li></a>
            <a href='#Contact'><li className='p-4'>Contact</li></a>

        </ul>
        <div onClick={handleNav} className='block md:hidden'>
            {nav ? <AiOutlineClose size={20}/> :<AiOutlineMenu size={20} /> }
            
        </div>
        <div className={nav ? 'fixed left-0 top-0 w-[40%] z-10 h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-700' : 'fixed left-[-100%]'}>
        <h1 className='w-full text-3xl font-bold text-[#00df9a]'>Optimal Audit</h1>
            <ul className='p-4'>
                <a href='/'><li className='p-4 border-b border-gray-600'>Home</li></a>
                <a href='/About'><li className='p-4 border-b border-gray-600'>About</li></a>
                <a href='/Services'><li className='p-4 border-b border-gray-600'>Services</li></a>
                <a href='#Contact'><li className='p-4 border-b border-gray-600'>Contact</li></a>

    
            </ul>
        </div>
    </div>
  )
}

export default Navbar